<template lang="pug">
  #app(ref="app")
    router-view
    header-component
    footer-component
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import ResizeService from 'src/services/ResizeService'
import HeaderComponent from '@/components/Header'
import FooterComponent from '@/components/Footer'

export default {

  name: 'app',

  components: {
    HeaderComponent,
    FooterComponent
  },

  computed: {
    ...mapState('browser', ['isMobile']),
  },

  beforeMount() {
    // listen to resize service and update the store with changes
    ResizeService.on(ResizeService.Events.RESIZE, this.resize)
    this.resize()
  },

  mounted() {
    // RIGHT CLICK CONTEXT MENU
    // window.addEventListener('contextmenu', (e) => {
    //   e.preventDefault()
    // })
  },

  methods: {

    ...mapMutations('browser', ['setDevice', 'setScale', 'setFontSize', 'setDimensions']),

    resize() {
      // put these in the store so we don't need to manually
      // listen to ResizeService everytime we want to react to
      // device/scale/size changes
      this.setDevice(ResizeService.device)
      this.setScale(ResizeService.scale)
      this.setFontSize(ResizeService.fontSize)
      this.setDimensions({
        width: ResizeService.width,
        height: ResizeService.height,
      })
    },

  },
}
</script>

<style lang="sass">
@import "src/styles/base.sass"

#app
  +full()

</style>
