<template lang="pug">

  transition(name="fade" appear)
    #header(v-if="loading.completed")
      h1 Resn Template v3
      #nav
        router-link(to="/" exact) Home

</template>

<script>

import { mapState } from 'vuex'

export default { 

  computed: {
    ...mapState('app', ['loading'])
  }
}

</script>

<style lang="sass" scoped>
@import "src/styles/common.sass"

#header
  position: fixed
  left: 2rem
  top: 2rem
  width: calc(100% - 4rem)
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  pointer-events: none

  h1
    font-size: 2rem

  #nav
    a
      display: inline-block
      margin-left: 2rem
      font-size: 1.5rem
      pointer-events: auto

      &.router-link-active
        text-decoration: underline
</style>
