<template lang="pug">

  transition(name="fade" appear)
    #footer(v-if="loading.completed")
      p resn 2020

</template>

<script>

import { mapState } from 'vuex'

export default {

  computed: {
    ...mapState('app', ['loading'])
  }
  
}

</script>

<style lang="sass" scoped>
@import "src/styles/common.sass"

#footer
  position: fixed
  left: 0
  bottom: 2rem
  text-align: center
  width: 100%
  pointer-events: none
</style>
