<template lang="pug">

transition(name="loading")
  #loading

</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { PATHS } from 'src/config/app'

export default {
  name: PATHS.LOADING,
  components: {},
  computed: {
    ...mapState('app', [
      'loading',
      'debug',
      'route'
    ]),
  },
  mounted() {
    this.interval = setInterval(this.update)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    update() {
      if (this.loading.completed) {
        this.next()
      }
    },

    next() {
      clearInterval(this.interval)

      // on delay so you see 100%
      setTimeout(() => {
        this.$router.replace(this.route.afterLoad)
      }, 250)
    },
  },
}
</script>

<style lang="sass" scoped>
@import "src/styles/common.sass"

</style>
