import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { PATHS } from 'src/config/app'
// import AnalyticsService from '@/services/AnalyticsService'

// ROUTES
import Loading from '@/pages/Loading'

// MODE
// mode: 'hash', // eg /#/mypage
// mode: 'history', // eg /mypage (requires htaccess or equivalent for deploy)
// mode: 'abstract', // internal routing, no url change
const mode = 'abstract'

// INIT
Vue.use(Router)
const router = new Router({

  base: PATHS.BASE,
  mode: mode,

  // Routes
  routes: [

    {
      path: `${PATHS.BASE}${PATHS.LOADING}`,
      name: PATHS.LOADING,
      component: Loading,
    },

    {
      path: PATHS.BASE,
      name: PATHS.HOME,
      component: () =>{
        if(process.env.VUE_APP_FOODPANDA === "true") {
          return import(/* webpackChunkName: "foodpanda" */ '@/pages/Foodpanda')
        } else {
          return import(/* webpackChunkName: "delivery" */ '@/pages/Delivery')
        }
      }
    },

    // catch all/404
    {
      path: '*',
      redirect: PATHS.BASE
    }
  ],
})
export default router



// ROUTE GUARDS

// LOADING REDIRECT

// START IF ABSTRACT MODE
if (router.mode === 'abstract') {
  router.replace('/')
}

// AFTER HOOK
router.afterEach((to) => {
  if (to.matched.length > 0) {
    store.commit('app/setRoute', {
      parent: to.matched[0].name,
      child: (to.matched[1] || { name: null }).name,
    })
    // AnalyticsService.sendPageview(to.fullPath)
  }
})

window.router = router
