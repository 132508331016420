import Vue from 'vue'
import App from './apps/App.vue'
import router from './router'
import store from './store'
import Copydeck from '@/config/copydeck.json'

Vue.config.productionTip = false

Vue.mixin({
  computed: {
    lang_en() {
      return Copydeck.en
    },
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
